import {
  EXPERIMENT_INSTALL_MISSING_POST_PAGE_ADI,
  EXPERIMENT_USE_MA_INTEGRATION_KIT,
} from '@wix/communities-blog-experiments';
import experiments from './experiments';
import maIntegrationKit from './ma-integration-kit';
import actions from './actions';
import postPageMigration from './post-page-migration';
import paidPostMigration from './paid-post-migration';
import oldBlogMigration from './old-blog-migration';
import menu from './menu';
import pageService from './page';
import {
  MANAGE_POSTS_EVENT,
  SITE_WAS_FIRST_SAVED,
  SITE_WAS_PUBLISHED,
  DELETE_BLOG_EVENT,
  WIDGET_GFPP_CLICKED,
  COMPONENT_GFPP_CLICKED,
  OPEN_WIDGET_MAIN_SETTINGS,
  OPEN_WIDGET_LAYOUT_SETTINGS,
} from '../constants/events';
import dependentApps from '../constants/dependent-apps';
import { createMemberPages } from '../constants/member-pages';
import { withDefaultPageProps } from './sections';
import getAppManifest from './app-manifest';
import { OFFLINE_PAID_POST_MIGRATION_ID, OFFLINE_POST_PAGE_MIGRATION_ID } from '../constants/migrations';
import { TPA_PAGE_ID_BLOG } from '../constants/tpa-pages';
import translation from './translation';
import translations from '../translations.json';

export const handleEditorReady = async (context) => {
  await experiments.conduct();
  const locale = await context.sdk.environment.getLocale();
  translation.init(translations, locale);

  const installMissingPostPageForAdi = () =>
    experiments.isEnabled(EXPERIMENT_INSTALL_MISSING_POST_PAGE_ADI)
      ? actions.installMissingPostPage(context)
      : Promise.resolve();

  const memberPages = createMemberPages(context.assetsUrl);
  const membersAreaApps = [...Object.values(dependentApps), ...Object.values(memberPages)].map(withDefaultPageProps);

  return context.isADI
    ? Promise.all([postPageMigration.prepare(context), installMissingPostPageForAdi()])
    : Promise.all([
        experiments.isEnabled(EXPERIMENT_USE_MA_INTEGRATION_KIT)
          ? maIntegrationKit.integrateMembersAreaApps(membersAreaApps)
          : actions.setDependantApps(context),
        actions.installMissingPostPage(context),
        postPageMigration.prepare(context),
        menu.addPages(context),
        actions.setStyleParams(context),
      ]);
};

export const handleBlogInstalled = async ({ sdk, appToken, isADI }) => {
  await actions.initProvision({ sdk });

  if (experiments.isEnabled(EXPERIMENT_USE_MA_INTEGRATION_KIT)) {
    await actions.initBiService({ sdk, appToken });

    const shouldMigrateOldBlog = await oldBlogMigration.shouldMigrate({ sdk, isADI });
    if (shouldMigrateOldBlog) {
      return oldBlogMigration.migrate({ sdk });
    }

    const isMAInstalled = await maIntegrationKit.isMembersAreaInstalled();
    return isMAInstalled || actions.displayProvisioningModal({ sdk, appToken, isADI });
  }

  const [membersApi] = await Promise.all([
    actions.getMembersApi({ sdk, appToken }),
    actions.initBiService({ sdk, appToken }),
  ]);
  const isMAInstalled = Boolean(membersApi);

  const shouldMigrateOldBlog = await oldBlogMigration.shouldMigrate({ sdk, isADI });
  if (shouldMigrateOldBlog) {
    return Promise.all([
      isMAInstalled ? actions.installBlogSections({ sdk, appToken }) : Promise.resolve(),
      oldBlogMigration.migrate({ sdk }),
    ]);
  }

  return isMAInstalled
    ? actions.installBlogSections({ sdk, appToken })
    : actions.displayProvisioningModal({ sdk, appToken, isADI });
};

export const handleGFPPClicked = ({ id, componentRef, context, componentRole }) => {
  if (id === OPEN_WIDGET_MAIN_SETTINGS) {
    actions.openMainSettings(context, componentRef, componentRole);
  }

  if (id === OPEN_WIDGET_LAYOUT_SETTINGS) {
    actions.openLayoutSettings(context, componentRef, componentRole);
  }
};

export const handleOnEvent = async ({ eventType, eventPayload }, context) => {
  switch (eventType) {
    case SITE_WAS_FIRST_SAVED:
      return actions.installMissingPostPage({ ...context, instance: eventPayload.instance });
    case SITE_WAS_PUBLISHED:
      return postPageMigration.start(context);
    case MANAGE_POSTS_EVENT:
      return (
        context.sdk &&
        context.sdk.editor.openDashboardPanel(context.appToken, {
          url: 'blog',
          closeOtherPanels: false,
        })
      );
    case DELETE_BLOG_EVENT:
      const blogPage = await pageService.find({ ...context, tpaPageId: TPA_PAGE_ID_BLOG });
      return context.sdk.document.pages.remove(context.appToken, { pageRef: { id: blogPage.id } });
    case WIDGET_GFPP_CLICKED: {
      const { id, componentRef } = eventPayload;
      return handleGFPPClicked({ id, componentRef, context });
    }
    case COMPONENT_GFPP_CLICKED: {
      // componentRole would be used creating widget's inner component's settings panels
      const { id, controllerRef, role } = eventPayload;
      return handleGFPPClicked({ id, componentRef: controllerRef, context, componentRole: role });
    }
    default:
      break;
  }
};

export const handleGetAppManifest = ({ sdk, appToken }) => {
  if (!sdk) {
    return {};
  }

  return getAppManifest(sdk.info.getSdkVersion().scriptSrc, appToken);
};

export const handleMigrateAction = (context, { migrationId } = {}) => {
  switch (migrationId) {
    case OFFLINE_POST_PAGE_MIGRATION_ID:
      return postPageMigration.migrateOffline(context);
    case OFFLINE_PAID_POST_MIGRATION_ID:
      return paidPostMigration.migrateOffline(context);
    default:
      return Promise.resolve();
  }
};

export default {
  handleBlogInstalled,
  handleEditorReady,
  handleOnEvent,
  handleGetAppManifest,
  handleMigrateAction,
};
