import { blogAppDefId } from './apps';
import { SECTIONS } from './sections';
import translation from '../services/translation';

const { translate } = translation;

const formatDescription = ({ text = '', bulletPoints = [] } = {}) =>
  `${text}<ul>${bulletPoints.map((bulletPoint) => `<li>${bulletPoint}</li>`).join('')}</ul>`;

export const createMemberPages = (assetsUrl) => {
  const getImageUrl = (fileName) => `${assetsUrl}/member-pages/${fileName}`;
  const iconURL = getImageUrl('blog-icon.png');
  const origin = translate('member-pages.origin');

  const memberPages = {
    [SECTIONS.POSTS]: {
      numbers: {
        key: 'postsCount',
        default: 0,
      },
      method: 'addSection',
      appDefinitionId: blogAppDefId,
      pageId: 'member-posts-page',
      urlOverride: 'blog-posts',
      visibleForRoles: ['admin', 'blog_writer', 'blog_editor'],
      page: {
        name: translate('member-pages.posts.name'),
        shortDescription: translate('member-pages.posts.short-description'),
        description: formatDescription({
          text: translate('member-pages.posts.description.text'),
          bulletPoints: [
            translate('member-pages.posts.description.bullet-point-1'),
            translate('member-pages.posts.description.bullet-point-2'),
            translate('member-pages.posts.description.bullet-point-3'),
            translate('member-pages.posts.description.bullet-point-4'),
          ],
        }),
        iconURL,
        origin,
        isNew: false,
        screenshots: [getImageUrl('posts-screenshot-desktop.png'), getImageUrl('posts-screenshot-mobile.png')],
      },
    },
    [SECTIONS.COMMENTS]: {
      numbers: {
        key: 'commentsCount',
        default: 0,
      },
      method: 'addSection',
      appDefinitionId: blogAppDefId,
      pageId: 'member-comments-page',
      urlOverride: 'blog-comments',
      menuOrder: 2,
      page: {
        name: translate('member-pages.comments.name'),
        shortDescription: translate('member-pages.comments.short-description'),
        description: formatDescription({
          text: translate('member-pages.comments.description.text'),
          bulletPoints: [
            translate('member-pages.comments.description.bullet-point-1'),
            translate('member-pages.comments.description.bullet-point-2'),
            translate('member-pages.comments.description.bullet-point-3'),
          ],
        }),
        iconURL,
        origin,
        isNew: false,
        screenshots: [getImageUrl('comments-screenshot-desktop.png'), getImageUrl('comments-screenshot-mobile.png')],
      },
    },
    [SECTIONS.LIKES]: {
      numbers: {
        key: 'likesCount',
        default: 0,
      },
      method: 'addSection',
      appDefinitionId: blogAppDefId,
      pageId: 'member-likes-page',
      urlOverride: 'blog-likes',
      menuOrder: 2,
      page: {
        name: translate('member-pages.likes.name'),
        shortDescription: translate('member-pages.likes.short-description'),
        description: formatDescription({
          text: translate('member-pages.likes.description.text'),
          bulletPoints: [
            translate('member-pages.likes.description.bullet-point-1'),
            translate('member-pages.likes.description.bullet-point-2'),
          ],
        }),
        iconURL,
        origin,
        isNew: false,
        screenshots: [getImageUrl('likes-screenshot-desktop.png'), getImageUrl('likes-screenshot-mobile.png')],
      },
    },
    [SECTIONS.DRAFTS]: {
      numbers: {
        key: 'draftsCount',
        default: 0,
      },
      method: 'addSection',
      appDefinitionId: blogAppDefId,
      pageId: 'member-drafts-page',
      social: false,
      showInLoginMenu: true,
      urlOverride: 'my-drafts',
      menuOrder: 2,
      visibleForRoles: ['admin', 'blog_writer', 'blog_editor'],
      page: {
        name: translate('member-pages.drafts.name'),
        shortDescription: translate('member-pages.drafts.short-description'),
        description: formatDescription({
          text: translate('member-pages.drafts.description.text'),
          bulletPoints: [
            translate('member-pages.drafts.description.bullet-point-1'),
            translate('member-pages.drafts.description.bullet-point-2'),
            translate('member-pages.drafts.description.bullet-point-3'),
          ],
        }),
        iconURL,
        origin,
        isNew: false,
        screenshots: [getImageUrl('drafts-screenshot-desktop.png'), getImageUrl('drafts-screenshot-mobile.png')],
      },
    },
  };

  return memberPages;
};
